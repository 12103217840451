<template>
  <div class="card shadow-sm">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        ปรับปรุงยอดยกมา : OP2
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >เพิ่มข้อมูล
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200"
            >
              <th>เลขที่เอกสาร</th>
              <th>รหัสสินค้า</th>
              <th>ชื่อสินค้า</th>
              <th>คลัง</th>
              <th>จำนวน</th>
              <th>ราคาซื้อ/หน่วย</th>
              <th>ส่วนลด/หน่วย</th>
              <th>VAT</th>
              <th>มูลค่าไม่รวม VAT</th>
              <th>status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Tiger Nixon</td>
              <td>System Architect</td>
              <td>Edinburgh</td>
              <td>61</td>
              <td>2011/04/25</td>
              <td>Edinburgh</td>
              <td>Edinburgh</td>
              <td>2011/04/25</td>
              <td>61</td>
              <td>state</td>
              <td>
                <!-- <div>
                <button
                  type="button"
                  class="btn btn-link btn-color-muted btn-active-color-success p-0"
                  @click="goToEdit()"
                >
                  <i class="bi bi-pencil-square"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                  @click="goToDelete()"
                >
                  <i class="bi bi-trash3-fill"></i>
                </button>
              </div> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer">
      <ul class="pagination">
        <li class="page-item previous disabled">
          <a href="#" class="page-link"><i class="previous"></i></a>
        </li>
        <li v-for="n in 5" :key="n" class="page-item">
          <a class="page-link">{{ n }}</a>
        </li>
        <li class="page-item next">
          <a href="#" class="page-link"><i class="next"></i></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  watch: {
    $route(to, form) {
      console.log({ to });
      console.log({ form });
    },
  },
  methods: {
    goToNew() {
      // this.$router.push({ name: "amount-quoted-new" });
      this.$router.push("/manage-system/op2/new");
    },
    goToEdit() {
      this.$router.push({
        name: "OP2-edit",
        query: {
          id: "123",
        },
      });
    },
    goToDelete() {},
  },
};
</script>
